exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/ArticlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-bank-page-tsx": () => import("./../../../src/templates/BankPage.tsx" /* webpackChunkName: "component---src-templates-bank-page-tsx" */),
  "component---src-templates-fund-page-tsx": () => import("./../../../src/templates/FundPage.tsx" /* webpackChunkName: "component---src-templates-fund-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-lawyer-page-tsx": () => import("./../../../src/templates/LawyerPage.tsx" /* webpackChunkName: "component---src-templates-lawyer-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-real-estate-project-page-tsx": () => import("./../../../src/templates/RealEstateProjectPage.tsx" /* webpackChunkName: "component---src-templates-real-estate-project-page-tsx" */)
}

