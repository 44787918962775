/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// export const onClientEntry = () => {
//     // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//     if (!(`IntersectionObserver` in window)) {
//       import(`intersection-observer`);
//     }
//   };
import React from 'react';

import { LocationContext } from './src/contexts/LocationContext';
import { GlobalStateProvider } from './src/state/globalStateContext';

export const wrapPageElement = ({ element, props }) => {
  return (
    <GlobalStateProvider>
      <LocationContext.Provider value={props.location}>{element}</LocationContext.Provider>
    </GlobalStateProvider>
  );
};

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
